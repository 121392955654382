import { ProductData } from "./product-data";
import allProducts from "./products.json";
import { TierData } from "./tier-data";
import { Platform } from "./platform";

export const getTierProducts = (tier: TierData) =>
  tier.products
    ? (tier.products.map((productId) => allProducts.find((product) => product.id === productId)) as ProductData[])
    : [];

export const getProductsTotalPrice = (products: ProductData[]) =>
  products.length > 0
    ? products.reduce((previousProductsPrice, product) => previousProductsPrice + product.price.usd, 0)
    : 0;

export const isTierCompatibleWithPlatform = (tier: TierData, platform: Platform) =>
  tier.products
    ? tier.products
        .map(getProductById)
        .every((product) => isProductCompatibleWithPlatform(product as ProductData, platform))
    : false;

export const isProductCompatibleWithPlatform = (product: ProductData, platform: Platform) =>
  product.compatibility.includes(platform);

export const getProductById = (productId: string) => allProducts.find((product) => product.id === productId);

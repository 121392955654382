import React, { FunctionComponent } from "react";
import { FaDesktop, FaPlaystation, FaXbox } from "react-icons/fa";
import { useIntl } from "react-intl";

import { TierData } from "./data/tier-data";
import { ProductData } from "./data/product-data";
import { getProductsTotalPrice, getTierProducts, isTierCompatibleWithPlatform } from "./data/utils";

import TierIcon from "./tier-icon";
import ProductDetails from "./product-details";

import * as classes from "./tier-details.module.scss";
import { Platform } from "./data/platform";

interface TierProps {
  tier: TierData;
}

const TierDetails: FunctionComponent<TierProps> = ({ tier }) => {
  const { formatMessage } = useIntl();

  const products: ProductData[] = getTierProducts(tier);
  const totalPrice: number = getProductsTotalPrice(products);

  return (
    <div className={classes.tier}>
      <div className={classes.header}>
        <h3>{tier.name}</h3>
        <TierIcon tier={tier} />
      </div>
      <div className={classes.price}>${totalPrice}</div>
      <div className={classes.description}>{formatMessage({ id: `tier.${tier.id}.description` })}</div>
      <div className={classes.compatibility}>
        <h5>{formatMessage({ id: "common.compatibility" })}</h5>
        {isTierCompatibleWithPlatform(tier, Platform.PC) && <FaDesktop className="m-1" />}
        {isTierCompatibleWithPlatform(tier, Platform.Xbox) && <FaXbox className="m-1" />}
        {isTierCompatibleWithPlatform(tier, Platform.PlayStation) && <FaPlaystation className="m-1" />}
      </div>
      <div className={classes.parts}>
        <h5>{formatMessage({ id: "common.parts" })}</h5>
        {products.map((product, index) => (
          <ProductDetails key={index} product={product} />
        ))}
      </div>
    </div>
  );
};

export default TierDetails;

import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import Layout from "../shared/layout";
import SEO from "../shared/seo";
import BuyersGuide from "../buyers-guide/buyers-guide";

const IndexPage: FunctionComponent = () => {
  const { formatMessage, locale } = useIntl();

  return (
    <Layout>
      <SEO
        title={formatMessage({ id: "page.home" })}
        description={formatMessage({ id: "home.seo-description" })}
        lang={locale}
      />
      <BuyersGuide />
    </Layout>
  );
};

export default IndexPage;

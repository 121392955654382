import React, { FunctionComponent, useState } from "react";

import * as classes from "./buyers-guide.module.scss";
import Tier from "./tier";
import tiers from "./data/tiers.json";
import TierDetails from "./tier-details";

const BuyersGuide: FunctionComponent = () => {
  const [selectedTier, setSelectedTier] = useState<any>(tiers[0]);
  const toggleSelectedTier = (clickedTier: any) => {
    if (clickedTier.id === selectedTier?.id) {
      setSelectedTier(null);
    } else {
      setSelectedTier(clickedTier);
    }
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.tiers} px-3 pt-3`}>
        {tiers.map((tier, index) => (
          <Tier
            key={index}
            tier={tier}
            isSelected={selectedTier?.id === tier.id}
            onClick={() => toggleSelectedTier(tier)}
          />
        ))}
      </div>
      <div className={`${classes.sidePanel} px-3 pt-4`}>
        <TierDetails tier={selectedTier ?? tiers[0]} />
      </div>
    </div>
  );
};

export default BuyersGuide;

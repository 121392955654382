import React, { FunctionComponent, ReactElement, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

import * as classes from "./accordion.module.scss";

interface AccordionProps {
  leftText: string;
  rightText: string;
  isSelected?: boolean;
  isExpandedByDefault?: boolean;
  icon?: ReactElement;
  onClick?: (event: React.MouseEvent) => void;
}

export const Accordion: FunctionComponent<AccordionProps> = ({
  leftText,
  rightText,
  isExpandedByDefault,
  isSelected,
  onClick,
  icon,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault ?? false);
  const toggleIsExpanded = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
    if (onClick) {
      onClick(event);
    }
  };

  const isOpened = isSelected === undefined ? isExpanded : isSelected;

  return (
    <div className={`${classes.accordion} ${isSelected ? classes.selected : ""}`}>
      <div className={classes.header} onClick={toggleIsExpanded}>
        {icon && <span className={classes.icon}>{icon}</span>}
        <span className={classes.leftText}>{leftText}</span>
        <span className={classes.rightText}>{rightText}</span>
        <span className={classes.chevron} aria-expanded={isOpened}>
          <FaChevronDown className={`${isOpened ? classes.rotate : ""}`} />
        </span>
      </div>
      <div className={classes.content} aria-expanded={!isOpened}>
        {children}
      </div>
    </div>
  );
};

import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import wheelIcon from "../assets/images/wheel-white.svg";
import wheelBaseIcon from "../assets/images/wheelbase-white.svg";
import pedalsIcon from "../assets/images/pedals-white.svg";
import hShifterIcon from "../assets/images/h-shifter-white.svg";
import seqShifterIcon from "../assets/images/seq-shifter-white.svg";

import { ProductData } from "./data/product-data";
import { ProductType } from "./data/product-type";

import * as classes from "./product-type-icon.module.scss";

const iconByProductType: Map<ProductType, string> = new Map([
  [ProductType.Wheel, wheelIcon],
  [ProductType.Wheelbase, wheelBaseIcon],
  [ProductType.Pedals, pedalsIcon],
  [ProductType.HPatternShifter, hShifterIcon],
  [ProductType.SequentialShifter, seqShifterIcon],
]);
const altTextByProductType: Map<ProductType, string> = new Map([
  [ProductType.Wheel, "common.wheel"],
  [ProductType.Wheelbase, "common.wheelbase"],
  [ProductType.Pedals, "common.pedals"],
  [ProductType.HPatternShifter, "common.h-pattern-shifter"],
  [ProductType.SequentialShifter, "common.sequential-shifter"],
]);

interface ProductTypeIconProps {
  product: ProductData;
}

const ProductTypeIcon: FunctionComponent<ProductTypeIconProps> = ({ product }) => {
  const { formatMessage } = useIntl();
  const icons = product.type.map((type) => iconByProductType.get(type));
  const altTexts = product.type.map((type) => altTextByProductType.get(type));

  return (
    <>
      {icons.map((icon, index) => (
        <img key={altTexts[index]} className={classes.icon} src={icon} alt={formatMessage({ id: altTexts[index] })} />
      ))}
    </>
  );
};

export default ProductTypeIcon;

import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

import Header from "./header/header";
import Footer from "./footer/footer";

import "../main.scss";
import * as classes from "./layout.module.scss";

const Layout: FunctionComponent = ({ children }) => (
  <div className={classes.layout}>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" rel="stylesheet" />
    </Helmet>
    <Header />
    <main className={classes.main}>{children}</main>
    <Footer />
  </div>
);

export default Layout;

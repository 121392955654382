import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

const Footer: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const currentYear = new Date().getFullYear();
  return (
    <footer className="p-2">
      <hr />
      <p className="fs-6 text-center fw-lighter">{formatMessage({ id: "footer.copyright" }, { currentYear })}</p>
    </footer>
  );
};

export default Footer;

import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import * as classes from "./tier.module.scss";

import wheelIcon from "../assets/images/wheel.svg";
import wheelBaseIcon from "../assets/images/wheelbase.svg";
import pedalsIcon from "../assets/images/pedals.svg";
import hShifterIcon from "../assets/images/h-shifter.svg";
import seqShifterIcon from "../assets/images/seq-shifter.svg";

import { ProductData } from "./data/product-data";
import { TierData } from "./data/tier-data";

import TierIcon from "./tier-icon";
import TierDetails from "./tier-details";
import { Accordion } from "../shared/accordion/accordion";
import { getProductsTotalPrice, getTierProducts } from "./data/utils";

interface TierProps {
  tier: TierData;
  isSelected: boolean;
  onClick: (event: React.MouseEvent) => void;
}

const Tier: FunctionComponent<TierProps> = ({ tier, isSelected, onClick }) => {
  const { formatMessage } = useIntl();

  const products: ProductData[] = getTierProducts(tier);
  const totalPrice: number = getProductsTotalPrice(products);

  const wheel = products.find((product) => product.type.includes("wheel")) as ProductData;
  const wheelbase = products.find((product) => product.type.includes("wheelbase")) as ProductData;
  const pedals = products.find((product) => product.type.includes("pedals")) as ProductData;
  const hPatternShifter = products.find((product) => product.type.includes("hPatternShifter")) as ProductData;
  const sequentialShifter = products.find((product) => product.type.includes("sequentialShifter")) as ProductData;

  return (
    <Accordion
      leftText={tier.name}
      rightText={`$${totalPrice}`}
      icon={<TierIcon tier={tier} />}
      onClick={onClick}
      isSelected={isSelected}
    >
      {tier.products && tier.products.length > 0 && (
        <>
          <div className={classes.table}>
            <table className="w-100">
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    <img src={wheelIcon} className={classes.headerIcon} />
                    <span>{formatMessage({ id: "common.wheel" })}</span>
                  </th>
                  <th scope="col" className="text-center">
                    <img src={wheelBaseIcon} className={classes.headerIcon} />
                    <span>{formatMessage({ id: "common.wheelbase" })}</span>
                  </th>
                  <th scope="col" className="text-center">
                    <img src={pedalsIcon} className={classes.headerIcon} />
                    <span>{formatMessage({ id: "common.pedals" })}</span>
                  </th>
                  <th scope="col" className="text-center">
                    <img src={hShifterIcon} className={classes.headerIcon} />
                    <span>{formatMessage({ id: "common.h-pattern-shifter" })}</span>
                  </th>
                  <th scope="col" className="text-center">
                    <img src={seqShifterIcon} className={classes.headerIcon} />
                    <span>{formatMessage({ id: "common.sequential-shifter" })}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {wheel.id === wheelbase.id && wheelbase.id === pedals.id && (
                    <td colSpan={3} className="text-center">
                      <div className={classes.productName}>{wheel.name}</div>
                      <div className={classes.productPrice}>${wheel.price.usd}</div>
                    </td>
                  )}
                  {wheel.id === wheelbase.id && wheelbase.id !== pedals.id && (
                    <>
                      <td colSpan={2} className="text-center">
                        <div className={classes.productName}>{wheel.name}</div>
                        <div className={classes.productPrice}>${wheel.price.usd}</div>
                      </td>
                      <td className="text-center">
                        <div className={classes.productName}>{pedals.name}</div>
                        <div className={classes.productPrice}>${pedals.price.usd}</div>
                      </td>
                    </>
                  )}
                  {wheel.id !== wheelbase.id && wheelbase.id !== pedals.id && (
                    <>
                      <td className="text-center">
                        <div className={classes.productName}>{wheel.name}</div>
                        <div className={classes.productPrice}>${wheel.price.usd}</div>
                      </td>
                      <td className="text-center">
                        <div className={classes.productName}>{wheelbase.name}</div>
                        <div className={classes.productPrice}>${wheelbase.price.usd}</div>
                      </td>
                      <td className="text-center">
                        <div className={classes.productName}>{pedals.name}</div>
                        <div className={classes.productPrice}>${pedals.price.usd}</div>
                      </td>
                    </>
                  )}
                  {hPatternShifter && sequentialShifter && hPatternShifter.id === sequentialShifter.id && (
                    <td colSpan={2} className="text-center">
                      <div className={classes.productName}>{hPatternShifter.name}</div>
                      <div className={classes.productPrice}>${hPatternShifter.price.usd}</div>
                    </td>
                  )}
                  {hPatternShifter && hPatternShifter.id !== sequentialShifter?.id && (
                    <td className={`text-center`}>
                      <div className={classes.productName}>{hPatternShifter.name}</div>
                      <div className={classes.productPrice}>${hPatternShifter.price.usd}</div>
                    </td>
                  )}
                  {sequentialShifter && hPatternShifter.id !== sequentialShifter?.id && (
                    <td className={`text-center`}>
                      <div className={classes.productName}>{sequentialShifter.name}</div>
                      <div className={classes.productPrice}>${sequentialShifter.price.usd}</div>
                    </td>
                  )}
                  {!hPatternShifter && <td className={`text-center`}>None</td>}
                  {!sequentialShifter && <td className={`text-center`}>None</td>}
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.mobileTierDetails}>
            <TierDetails tier={tier} />
          </div>
        </>
      )}
    </Accordion>
  );
};

export default Tier;

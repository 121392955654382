import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Link } from "gatsby";

import * as classes from "./header.module.scss";

const Header: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  return (
    <header className={`${classes.header} px-3 py-2 shadow`}>
      <Link to={"/"}>
        <span className="fs-2 mx-2">🏁</span>
        <span className="fs-2 mx-2">SimGui.de</span>
      </Link>
      <span className="mx-3 fst-italic">{formatMessage({ id: "common.motto" })}</span>
    </header>
  );
};

export default Header;

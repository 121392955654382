import React, { FunctionComponent } from "react";
import { FaDesktop, FaPlaystation, FaXbox } from "react-icons/fa";
import { useIntl } from "react-intl";

import { Accordion } from "../shared/accordion/accordion";
import { isSmallScreen } from "../shared/layout-utils";

import * as classes from "./product-details.module.scss";

import { ProductData } from "./data/product-data";
import ProductTypeIcon from "./product-type-icon";
import { isProductCompatibleWithPlatform } from "./data/utils";
import { Platform } from "./data/platform";

interface ProductProps {
  product: ProductData;
}

const ProductDetails: FunctionComponent<ProductProps> = ({ product }) => {
  const { formatMessage } = useIntl();
  const isMobile = isSmallScreen();

  return (
    <Accordion
      leftText={product.name}
      rightText={`$${product.price.usd}`}
      isExpandedByDefault={!isMobile}
      icon={<ProductTypeIcon product={product} />}
    >
      <div className="d-flex px-2 py-3">
        <div className={classes.description}>{product.description || "Description coming soon!"}</div>
        {product.imageUrl && <img src={product.imageUrl} className={classes.image} />}
      </div>
      <div className={classes.compatibility}>
        <div className="me-1">{formatMessage({ id: "common.compatibility" })}:</div>
        {isProductCompatibleWithPlatform(product, Platform.PC) && <FaDesktop className="m-1" />}
        {isProductCompatibleWithPlatform(product, Platform.Xbox) && <FaXbox className="m-1" />}
        {isProductCompatibleWithPlatform(product, Platform.PlayStation) && <FaPlaystation className="m-1" />}
      </div>
    </Accordion>
  );
};

export default ProductDetails;

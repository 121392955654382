import React, { FunctionComponent } from "react";

import bumperCarIcon from "../assets/images/tiers/bumper-car.svg";
import goKartIcon from "../assets/images/tiers/go-kart.svg";
import superKartIcon from "../assets/images/tiers/superkart.svg";
import hotHatchIcon from "../assets/images/tiers/hot-hatch.svg";
import sportsCarIcon from "../assets/images/tiers/sports-car.svg";
import gt4Icon from "../assets/images/tiers/gt4.svg";
import gt3Icon from "../assets/images/tiers/gt3.svg";
import gteIcon from "../assets/images/tiers/gte.svg";
import formulaEIcon from "../assets/images/tiers/formula-e.svg";
import nascarIcon from "../assets/images/tiers/nascar.svg";
import formula3Icon from "../assets/images/tiers/formula-3.svg";
import dtmIcon from "../assets/images/tiers/dtm.svg";
import lmp2Icon from "../assets/images/tiers/lmp2.svg";
import hypercarIcon from "../assets/images/tiers/hypercar.svg";
import formula2Icon from "../assets/images/tiers/formula-2.svg";
import indycarIcon from "../assets/images/tiers/indycar.svg";
import formula1Icon from "../assets/images/tiers/formula-1.svg";

import { TierData } from "./data/tier-data";

const tierIconById: Map<string, string> = new Map([
  ["bumper-car", bumperCarIcon],
  ["go-kart", goKartIcon],
  ["superkart", superKartIcon],
  ["hot-hatch", hotHatchIcon],
  ["sports-car", sportsCarIcon],
  ["gt4", gt4Icon],
  ["gt3", gt3Icon],
  ["gte", gteIcon],
  ["formula-e", formulaEIcon],
  ["nascar", nascarIcon],
  ["formula-3", formula3Icon],
  ["dtm", dtmIcon],
  ["lmp2", lmp2Icon],
  ["hypercar", hypercarIcon],
  ["formula-2", formula2Icon],
  ["indycar", indycarIcon],
  ["formula-1", formula1Icon],
]);

interface TierIconProps {
  tier: TierData;
}

const TierIcon: FunctionComponent<TierIconProps> = ({ tier }) => {
  const icon = tierIconById.get(tier.id);

  return <>{icon && <img src={icon} alt={tier.name} />}</>;
};

export default TierIcon;
